<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
  >
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
    >
      <a-form-model-item
        label="设备"
        prop="devId"
        v-if="!devId"
      >
        <a-select
          placeholder="请选择"
          v-model="form.devId"
          style="width: 100%"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          allow-clear
        >
          <a-select-option
            v-for="(d, index) in devList"
            :key="index"
            :value="d.id"
          >{{ d.devName }}</a-select-option>
        </a-select></a-form-model-item>
      <a-form-model-item
        label="视频编号"
        prop="monitorSerial"
      >
        <a-input
          v-model="form.monitorSerial"
          :maxLength="100"
          placeholder="请输入视频编号"
        />
      </a-form-model-item>
      <a-form-model-item
        label="视频编号秘钥"
        prop="monitorCode"
      >
        <a-input
          v-model="form.monitorCode"
          :maxLength="30"
          placeholder="请输入视频秘钥"
        />
      </a-form-model-item>
      <a-form-model-item
        label="备注"
        prop="remark"
      >
        <a-input
          v-model="form.remark"
          :maxLength="30"
          placeholder="请输入设备注"
        />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button
            type="primary"
            :loading="submitLoading"
            @click="submitForm"
          >
            保存
          </a-button>
          <a-button
            type="dashed"
            @click="cancel"
          >
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getCamera, addCamera, updateCamera } from '@/api/project/camera'
import { listDevice } from '@/api/project/device'
export default {
  name: 'CreateForm',
  props: ['devId'],
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      devList: [],
      // 表单参数
      form: {
        id: null,
        devId: undefined,
        monitorCode: null,
        monitorSerial: null,
        remark: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        devId: [
          { required: true, message: '请选择设备', trigger: 'blur' }
        ],
        monitorCode: [
          { required: true, message: '请输入视频秘钥', trigger: 'blur' }
        ],
        monitorSerial: [
          { required: true, message: '请输入视频编号', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    listDevice().then(res => {
      this.devList = res.data
      console.log(res, '大大大大大')
    })
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        devId: undefined,
        monitorCode: null,
        monitorSerial: null,
        remark: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getCamera({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateCamera(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            if (this.devId) {
              this.form.devId = this.devId
            }
            addCamera(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
