<template>
  <a-drawer
    width="70%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
  >
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <camera
      v-show="formType == 1"
      :devId="devId"
    ></camera>
    <vehicle
      v-show="formType == 2"
      :devId="devId"
    ></vehicle>
  </a-drawer>
</template>

<script>
import camera from '../../camera/index.vue'
import vehicle from '../../vehicle/index.vue'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    camera,
    vehicle
  },
  dicts: ['sys_normal_disable', 'sys_user_sex'],
  data () {
    return {
      previewVisible: false,
      fileList: [
      ],
      agentList: [],
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        tenantId: undefined,
        userId: undefined,
        idParamList: []
      },
      devId: '',
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        tenantId: [
          { required: true, message: '请选择公司', trigger: 'blur' }
        ],
        userId: [
          { required: true, message: '请选择员工', trigger: 'blur' }
        ]
      },
      userList: [],
      companyList: [],
      deviceTypeList: []
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleJiaDai () {
      console.log(this.$refs.createForm, 'this.$refs.createFormthis.$refs.createFormthis.$refs.createForm==')
      this.$nextTick(() => {
        this.$refs.createForm.handleAdd()
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        tenantId: undefined,
        userId: undefined,
        idParamList: []
      }
    },
    /** 新增按钮操作 */
    handleAdd (e, row) {
      this.reset()
      this.formType = row
      this.open = true
      this.devId = e
      console.log(e, '大大大大大')
      this.$forceUpdate()
      if (row === 1) {
        this.formTitle = '视频'
      } else {
        this.formTitle = '来车记录'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-upload-preview {
  position: relative;
  width: 100px;
  height: 100px;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .mask {
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
}

.slect_icon {
  display: flex;
  align-items: center;

  .icon_cir {
    margin-left: 30px;
    font-size: 25px;
    cursor: pointer;
  }
}
</style>
