<template>
  <a-drawer
    width="35%"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
  >
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <div
      div
      id="playWind"
    >
    </div>
  </a-drawer>
</template>

<script>
import ezuikit from '@/utils/ezuikit.js'
var EZOPENDemo = null
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 1增加,2修改
      formType: 1,
      open: false
    }
  },
  filters: {
  },
  created () {

  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onClose () {
      this.open = false
      var stopPromise = EZOPENDemo.stop()
      stopPromise.then((data) => {
        console.log('promise 获取 数据', data)
      })
      EZOPENDemo.stop()
      EZOPENDemo.stopTalk()
      EZOPENDemo.destroy()
      this.$forceUpdate()
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    init (accessToken, seriesNum, e) {
      console.log(seriesNum, accessToken, 'accessTokenaccessTokenaccessToken')
      this.open = true
      if (e === 1) {
        this.formTitle = '查看视频'
        const ezopenInit = () => {
          EZOPENDemo = new EZUIKit.EZUIKitPlayer({
            id: 'playWind',
            width: '500',
            height: '300',
            template: 'pcLive',
            url: 'ezopen://open.ys7.com/' + seriesNum + '/1.live',
            accessToken: accessToken,
            header: ['capturePicture', 'save', 'zoom'], // 如果templete参数不为simple,该字段将被覆盖
            plugin: ['talk'], // 加载插件，talk-对讲
            // 视频下方底部控件
            footer: ['talk', 'broadcast', 'hd', 'fullScreen'], // 如果template参数不为simple,该字段将被覆盖
            audio: 1 // 是否默认开启声音 0 - 关闭 1 - 开启
          })
        }
        setTimeout(() => {

        }, 200)
        ezopenInit()
      } else {
        this.formTitle = '查看回放'
        window.EZOPENDemo = EZOPENDemo
        const url = 'ezopen://open.ys7.com/' + seriesNum + '/1.cloud.rec'
        console.log(url, '滴滴滴滴滴滴')
        const ezopenInit = () => {
          EZOPENDemo = new EZUIKit.EZUIKitPlayer({
            id: 'playWind',
            width: '500',
            height: '300',
            template: 'pcRec',
            url: url,
            accessToken: accessToken
          })
        }
        ezopenInit()
        console.log(accessToken, 'accessTokenaccessToken')
      }
      window.EZOPENDemo = EZOPENDemo
      console.log(EZOPENDemo, 'EZOPENDemoEZOPENDemo')
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        devId: undefined,
        monitorCode: null,
        monitorSerial: null,
        remark: null
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getDeviceType({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id) {
            updateDeviceType(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addDeviceType(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
